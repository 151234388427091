.App {
  height: 100%;
}

.ask-bg-dark {
  background-color: #2b2b2b;
  color: white;
}

.ask-bg-light {
  background-color: 'white' !important;
  color: black;
}

.ask-full-page {
  height: 100% !important;
  margin-bottom: 0 !important;
}

.ask-header {
  font-family: 'Lobster', cursive;
}

.ask-nav {
  font-family: 'Oswald', sans-serif;
}

.ask-text {
  font-family: 'Montserrat', sans-serif;
}

.ask-gold-text {
  color: #daa520;
}

.ask-gold-bg {
  background-color: #daa520;
  color: black;
}

.ask-silver-bg {
  background-color: #c0c0c0;
  color: black;
}

.ask-bronze-bg {
  background-color: #cd7f32;
  color: black;
}

.ask-greeting {
  font-family: 'Lobster', cursive;
  font-size: 5em;
}

.ask-bq-body {
  color: white;
  background-image: url('./assets/question-mark.jpg');
}

.ask-time-body {
  color: black;
  background-image: url('./assets/time.jpg');
  background-position: center;
  border-radius: 15px;
}

.ask-bq-heading {
  font-size: 2em;
  font-family: 'Oswald', sans-serif;
}

.ask-bq-question {
  font-size: 2em;
  font-family: 'Montserrat', sans-serif;
}

.ask-bq-points {
  font-size: 2em;
  font-family: 'Oswald', sans-serif;
  color: #a3a3a3;
}

.ask-scores-btn {
  font-family: 'Oswald', sans-serif;
  font-size: 1.5em !important;
  min-width: 40%;
}

.ask-navbar-brand {
  font-family: 'Lobster', cursive;
  font-size: 1.75em;
}

.ask-navbar-brand:hover {
  text-decoration: none;
}

.ask-rounds-bg {
  background-color: rgb(55, 71, 79);
}

.ask-secondary-bg {
  background-color: #526a75;
}

.ask-red-bg {
  background-color: #5b0f00ff;
}

.ask-pain {
  font-size: 2em;
  color: #f5e614;
  font-family: 'Oswald', sans-serif;
}

.ask-suffering {
  font-size: 2em;
  color: #f59714;
  font-family: 'Oswald', sans-serif;
}

.ask-torment {
  font-size: 2em;
  color: #c9110e;
  font-family: 'Oswald', sans-serif;
}

.ask-category {
  font-size: 2em;
  color: #4f4f4f;
  font-family: 'Oswald', sans-serif;
}
