* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  min-height: 100%;
  margin-bottom: 0;
}

body {
 min-height: 100%;
 height: 100%;
 margin-bottom: 0;
}

#root {
  min-height: 100%;
  height: 100%;
  margin-bottom: 0;
}
